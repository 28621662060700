.container {
  padding: 0px;
  overflow: hidden;
}

.main {

  padding: 0px 120px;
}

@media only screen and (min-width: 1440px) {
  .container {
    margin: 0px calc((100vw - 1440px)/2);
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    margin-top: 0px;
    padding: 0px 24px;
  }
}

@media only screen and (max-width: 425px) {
  .main {
    margin-top: 0px;
    padding: 0px 8px;
  }
}
